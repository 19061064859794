const ConvertIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.332 2.00003H13.9987V4.6667"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 6.00003L14 2.00003"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66667 14H2V11.3334"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10L2 14"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.34538 1.46672C8.91204 1.38005 8.4587 1.33337 7.9987 1.33337C4.3187 1.33337 1.33203 4.32004 1.33203 8.00004C1.33203 8.46004 1.37871 8.90671 1.46537 9.33337"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.65234 14.5333C7.08568 14.62 7.53902 14.6667 7.99902 14.6667C11.679 14.6667 14.6657 11.68 14.6657 8.00002C14.6657 7.54669 14.619 7.10002 14.5323 6.66669"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { ConvertIcon };

import { useGomakeTheme } from "@/hooks/use-gomake-thme";
import { convertHeightToVH, convertWidthToVW } from "@/utils/adapter";
import { FONT_FAMILY } from "@/utils/font-family";
import { useMemo } from "react";

const useStyle = () => {
  const { theme, primaryColor, secondColor, errorColor } = useGomakeTheme();
  const CONTAINER_MAX_HEIGHT = "1007px";
  const CONTAINER_TABS_MAX_HEIGHT = "900px";
  const FOOTER_MAX_HEIGHT = "50px";

  const classes = useMemo(() => {
    return {
      mainContainerStyle: {
        
        backgroundColor: "#FDFDFD",
        marginBottom: 10,
      },
      buttonStyle: {
        width: convertWidthToVW(100),
        height: convertHeightToVH(50),
        marginRight: convertWidthToVW(10),
        backgroundColor: "#F135A3",
      },
      insideStyle: {
        width: "75%",
        height: "93%",
        overFlow: "hidden",
        maxWidth: "1240px",
        maxHeight: CONTAINER_MAX_HEIGHT,
        paddingLeft: "32px",
        paddingRight: "48px",
        paddingTop: "27px",
        paddingBottom: "27px",
        background: "#FDFDFD",


      },
      secondInsideStyle: {
        paddingLeft: 0,
        paddingRight: 0,
        height: "fit-content",
        width: 380,
      },
      subTitleStyle: {
        fontStyle: "normal",
        lineHeight: "normal",
        color: "#ED028C",
        ...FONT_FAMILY.Lexend(600, 16),
        marginBottom: 10,
      },
      tabsContainer: {
        height: "fit-content",
        maxHeight: CONTAINER_TABS_MAX_HEIGHT,
        overflow: "auto",
        position: 'sticky' as 'sticky',
      },
      footerStyle: {
        display: "flex",
        justifyContent: "flex-end",
        height: FOOTER_MAX_HEIGHT,
        width: "100%",
        background: "#fff",
        alignItems: "center",
      },
      bottomSectionStyle: {
        display: "flex",
        flexDirection: "column" as "column",
        width: "100%",
        height: "fit-content",
        overflow: "auto",
        flex:1
      },
      colStyle: {
        display: "flex",
        width: "180px",
        height: "68px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
      },
      inputStyle1: {
        width: "180px",
        height: "40px",
        flexShrink: 0,
        borderRadius: "4px",
        background: "#FFF",
        boxShadow: "0px 4px 60px 0px #00000014",
        border: "none",
        ...FONT_FAMILY.Lexend(500, 14),
        color: "#8283BE",
      },
      inputStyle: {
        border: "none",
        borderBottom: "1px solid black",
        borderBottomColor: "#2E3092",
        padding: "0.5rem",
        display: "inline-block",
        boxShadow: "none",
        outline: "1px solid white",
        ...FONT_FAMILY.Lexend(500, 14),
        color: "#8283BE",
      },
      headerStyle: {
        color: "var(--primary-900, #090A1D)",
        fontStyle: "normal",
        lineHeight: "normal",
        ...FONT_FAMILY.Lexend(500, 14),
      },
      buttonsStyle: {
        color: primaryColor(500),
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.14px",
        border: "none",
        background: "#FFF",
        ...FONT_FAMILY.Lexend(500, 14),
        cursor: "pointer",
      },
      tabStyle: {
        width: "62px",
        height: "20px",
        color: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "27px",
        fontStyle: "normal",
        lineHeight: "normal",
        borderRadius: "4px",
        background: "#ED028C",
        ...FONT_FAMILY.Lexend(500, 16),
      },
      filterStyle: {
        display: "flex",
        width: "50%",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "7px",
      },
      headers3Style: {
        color: "#F135A3",
        ...FONT_FAMILY.Lexend(500, 18),
      },
      tableContainer: {
        width: "100%",
      },
      switchHeaderStyle: {
        color: "var(--primary-900, #090A1D)",
        fontStyle: "normal",
        lineHeight: "normal",
        ...FONT_FAMILY.Lexend(400, 12),
      },
      autoButtonStyle: {
        padding: "10px 32px",
        borderRadius: "4px",
        background: "var(--second-500, #ED028C)",
        color: "var(--puree, #FFF)",
        TextAlign: "center",
        fontStyle: "normal",
        lineHeight: "normal",
        border: "none",
        ...FONT_FAMILY.Lexend(500, 16),
      },
      autoComplateStyle: {
        width: "180px",
        height: "40px",
        flexShrink: 0,
        borderRadius: "4px",
        background: "#FFF",
        boxShadow: "0px 4px 60px 0px #00000014",
        border: "none",
        ...FONT_FAMILY.Lexend(500, 14),
        color: "#8283BE",
      },
      selectStyle: {
        width: convertWidthToVW(150),
        height: convertHeightToVH(30),
      },
      textAreaStyle: {
        display: "flex",
        height: "80px",
        padding: "5px 7px",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "4px",
        border: `1px solid ${primaryColor(500)}`,
        color: "var(--medium-300, #9695C7)",
        fontStyle: "normal",
        lineHeight: "normal",
        ...FONT_FAMILY.Lexend(500, 12),
      },
      customerInfoStyle: {
        display: "flex",
        flexDirection: "row" as "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 10,
        flexWrap: "wrap" as "wrap",
        gap: 10,
      },
      labelTitleStyle: {
        ...FONT_FAMILY.Lexend(500, 14),
        color: primaryColor(900),
        justifyContent: "space-between",
        display: "flex",
        width: "100%"
      },
      dropDownListStyle: {
        width: "100%",
        borderRadius: 4,
        height: 40,
        backgroundColor: "#FFF",
        border: "0px",
      },
      plusInput: {
        ...FONT_FAMILY.Lexend(500, 14),
        color: secondColor(500),
        cursor: "pointer",
        paddingLeft: 5,
      },
      itemOnFirstContainer: {
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 10,
        minWidth: 180,
        position: "relative" as "relative",
      },
      required: {
        ...FONT_FAMILY.Lexend(500, 14),
        color: errorColor(500),
      },
      inputLbl: {
        color: primaryColor(900),
        ...FONT_FAMILY.Lexend(600, 14),
        display: "flex",
        //  justifyContent: 'space-between',
        alignItems: "flex-end",
      },
      addNewStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "5px",
      },
      lastOrderDetails: {
        color: "var(--second-500, #ED028C)",
        fontStyle: "normal",
        ...FONT_FAMILY.Lexend(500, 14),
        lineHeight: "normal",
        marginBottom: 10,
      }
    };
  }, [theme]);
  return {
    classes,
  };
};
export { useStyle };
